import request from '@/utils/request';
export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

export function logout(data) {
    return request({
        url: '/logout',
        method: 'get',
        data
    })
}
/** 获取验证码 */
export function getCode(data) {
    return request({
        url: '/sms',
        method: 'post',
         data
    })
}
//使用验证码登录
export function codeLogin(data) {
    return request({
        url: '/loginSms',
        method: 'post',
        data
    })
}