import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/utils/cookie';
import { getQueryVariable  } from '@/utils/index'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);
const routes = [
    {
        path: '/login',
        name: 'login',
        component: resolve => require(['@/views/Login.vue'],resolve),
        meta: {
            title: '登录'   // 标题设置
        }
    },
    /* {
        path: '/',
        component: Layout,
        hidden: true,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: resolve => require(['@/views/Home.vue'],resolve),
                meta:{title:'园区介绍'}
            },
            {
                path: '/personal',
                name: 'personal',
                component: resolve => require(['@/views/personal/personal.vue'],resolve),
                meta:{title:'个人中心'}
            },
        ]
    }, */
     {
        path: '/',
        name: 'console',
        component: function () {
            return import('@/views/console/console.vue');
        },
        redirect: '/console',
    }, 
    {
        path: '/login',
        name: 'login',
        component: resolve => require(['@/views/Login.vue'],resolve),
        meta: {
            title: '登录',   // 标题设置
            showMenu: false
        }
    },
     {
        path: '/home',
        name: 'Home',
        component: resolve => require(['@/views/Home.vue'],resolve),
        meta:{title:'园区介绍', showMenu: false}
    },
    {
        path: '/personal',
        name: 'personal',
        component: resolve => require(['@/views/personal/personal.vue'],resolve),
        meta:{title:'个人中心',showMenu: true}
    },
    {
        path: '/addInfor',
        name: 'personal',
        component: resolve => require(['@/views/personal/addInfor.vue'],resolve),
        meta:{title:'个人信息',showMenu: true}
    },
    {
        path: '/console',
        name: 'console',
        component: resolve => require(['@/views/console/console.vue'],resolve),
        meta:{title:'控制台',showMenu: true}
    },
    {
        path: '/addressBook',
        name: 'addressBook',
        component: resolve => require(['@/views/addressBook/address-book.vue'],resolve),
        meta:{title:'通讯录',showMenu: true}
    },
    {
        path: '/addAdddressBook',
        name: 'addAdddressBook',
        component: resolve => require(['@/views/addressBook/addAdddressBook.vue'],resolve),
        meta:{title:'新增联系人',showMenu: false}
    },
    {
        path: '/plotManagement',
        name: 'plotManagement',
        component: resolve => require(['@/views/plotManagement/polt-management.vue'],resolve),
        meta:{title:'地块管理',showMenu: true}
    },
    {
        path: '/addPlot',
        name: 'addPlot',
        component: resolve => require(['@/views/plotManagement/addPlot.vue'],resolve),
        meta:{title:'新增地块'}
    },
    {
        path: '/cropManagement',
        name: 'cropManagement',
        component: resolve => require(['@/views/cropManagement/crop-management.vue'],resolve),
        meta:{title:'作物管理',showMenu: false}
    },
    
    {
        path: '/addAgricultural',
        name: 'addAgricultural',
        component: resolve => require(['@/views/agriculturalRecords/addAgricultural.vue'],resolve),
        meta:{title:'新增记录',showMenu: false}
    },
    {
        path: '/agriculturalRecords',
        name: 'agriculturalRecords',
        component: resolve => require(['@/views/agriculturalRecords/agricultural-records.vue'],resolve),
        meta:{title:'农事记录',showMenu: true}
    },
    {
        path: '/notificationIssuance',
        name: 'notificationIssuance',
        component: resolve => require(['@/views/notificationIssuance/notification-issuance.vue'],resolve),
        meta:{title:'通知列表',showMenu: true}
    },
    {
        path: '/addNotificationIssuance',
        name: 'addNotificationIssuance',
        component: resolve => require(['@/views/notificationIssuance/add-notification-issuance.vue'],resolve),
        meta:{title:'通知下发'}
    },
    {
        path: '/pressReleases',
        name: 'pressReleases',
        component: resolve => require(['@/views/pressReleases/press-releases.vue'],resolve),
        meta:{title:'新闻列表',showMenu: true}
    },
    {
        path: '/addPress',
        name: 'addPress',
        component: resolve => require(['@/views/pressReleases/addPress.vue'],resolve),
        meta:{title:'新闻发布'}
    },
    {
        path: '/workOrder',
        name: 'workOrder',
        component: resolve => require(['@/views/workOrder/work-order.vue'],resolve),
        meta:{title:'农事服务',showMenu: true}
    },
    {
        path: '/addOrder',
        name: 'addOrder',
        component: resolve => require(['@/views/workOrder/addOrder.vue'],resolve),
        meta:{title:'添加任务',showMenu: true}
    },
    {
        path: '/reviewOrder',
        name: 'reviewOrder',
        component: resolve => require(['@/views/workOrder/reviewOrder.vue'],resolve),
        meta:{title:'提交',showMenu: false}
    },
    {
        path: '/viewPests',
        name: 'viewPests',
        component: resolve => require(['@/views/viewPests/viewPests.vue'],resolve),
        meta:{title:'病虫害识别',showMenu: true}
    },
    {
        path: '/seeViewPestInfo',
        name: 'seeViewPestInfo',
        component: resolve => require(['@/views/viewPests/seeViewPestInfo.vue'],resolve),
        meta:{title:'病虫害详情',showMenu: false}
    },
    {
        path: '/machineryManagement',
        name: 'machineryManagement',
        component: resolve => require(['@/views/machineryManagement/adminMachineryManagement/machineryManagement.vue'],resolve),
        meta:{title:'农机管理',showMenu: true}
    }, 
    {
        path: '/myMachinery',
        name: 'myMachinery',
        component: resolve => require(['@/views/machineryManagement/workMachineryManagement/myMachinery.vue'],resolve),
        meta:{title:'我的农机',showMenu: true}
    }, 
    {
        path: '/machineryInfo',
        name: 'machineryInfo',
        component: resolve => require(['@/views/machineryManagement/machineryInfo.vue'],resolve),
        meta:{title:'农机详情',showMenu: true}
    }, 
    {
        path: '/machineryMarket',
        name: 'machineryMarket',
        component: resolve => require(['@/views/machineryManagement/workMachineryManagement/machineryMarket.vue'],resolve),
        meta:{title:'农机市场',showMenu: true}
    }, 
    {
        path: '/alarm',
        name: 'alarm',
        component: resolve => require(['@/views/alarm/index.vue'],resolve),
        meta:{title:'设备告警',showMenu: true}
    }, 
    {
        path: '/scanQRCode',
        name: 'scanQRCode',
        component: resolve => require(['@/views/scanQRCode/scanQRCode.vue'],resolve),
        meta:{title:'溯源查看',showMenu: true}
    },
    {
        path: '/planA',
        name: 'planA',
        component: resolve => require(['@/views/suyuan/rootsH5PlanC.vue'],resolve),
        meta:{title:'溯源',showMenu: false}
    },
    {
        path: '/goodsInfo',
        name: 'goodsInfo',
        component: resolve => require(['@/views/suyuan/goodsInfo.vue'],resolve),
        meta:{title:'商品详情',showMenu: false}
    },
    {
        path: '/shoppingCar',
        name: 'shoppingCar',
        component: resolve => require(['@/views/suyuan/shoppingCar.vue'],resolve),
        meta:{title:'购物车',showMenu: false}
    },
    {
        path: '/planA/AplantsGrow',
        name: 'AplantsGrow',
        component: resolve => require(['@/views/suyuan/planA/plantsGrow.vue'],resolve),
        meta:{title:'新增',showMenu: false}
    },
    {
        path: '/planB',
        name: 'planB',
        component: resolve => require(['@/views/suyuan/rootsH5PlanB.vue'],resolve),
        meta:{title:'溯源',showMenu: false}
    },
    {
        path: '/planA/BplantsGrow',
        name: 'BplantsGrow',
        component: resolve => require(['@/views/suyuan/planB/plantsGrow.vue'],resolve),
        meta:{title:'新增',showMenu: false}
    },
    {
        path: '/planC',
        name: 'planC',
        component: resolve => require(['@/views/suyuan/rootsH5PlanC.vue'],resolve),
        meta:{title:'溯源',showMenu: false}
    },
    {
        path: '/planC/CplantsGrow',
        name: 'CplantsGrow',
        component: resolve => require(['@/views/suyuan/planC/plantsGrow.vue'],resolve),
        meta:{title:'新增',showMenu: false}
    },
    {
        path: '/lookPart',
        name: 'lookPart',
        component: resolve => require(['@/views/plotManagement/lookPart'],resolve),
        meta:{title:'查看地块',showMenu: false}
    },
];
const router = new VueRouter({
    mode: 'history',
    // mode: 'hash',
    base: process.env.BASE_URL,
    routes
});
const whiteList = ['/login'];
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    // if (getToken()) {
        if( to.path == '/planA' || to.path == '/planB' || to.path == '/plaC') {
            let plan = getQueryVariable('plan')
            switch (plan) {
                case 'a':next({name:'planA',query:{batchsCode:getQueryVariable('batchsCode')}});
                    break;
                case 'b':next({name:'planB',query:{batchsCode:getQueryVariable('batchsCode')}});
                    break;
                case 'c':next({name:'planC',query:{batchsCode:getQueryVariable('batchsCode')}});
                    break;
                default: next()
                    break;
            }
        } else {
            next()
        }
   /*  } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            //没有权限的路由
            next(`/login?redirect=${to.path}`);
        }
    } */
})
export default router;
 