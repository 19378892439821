<template>
    <div id="app">
        <layout-main v-if="$route.meta.showMenu"></layout-main>
        <router-view/>
    </div>
</template>
<script>
    import LayoutMain from './components/layout.vue';
    export default {
        components: {
            LayoutMain
        },
    }
</script>
<style lang="scss">
body,
html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #F1F2F8;
}
#app {
    width: 100%;
    height: 100%;
}
input:-webkit-autofill { 
    box-shadow: 0 0 0px 1000px white inset !important;
}
</style>
