import Cookies from 'js-cookie'

const UserId = 'UserId'
const TokenKey = 'Admin-Token'
const Username = 'user-name'
const OrgId = 'OrgId'
const OrgCode = 'OrgCode'
const Route = 'Route'
const Password = 'Password'
const UserType = 'UserType'

export function getUserId() {
    return Cookies.get(UserId)
}

export function setUserId(useId) {
    return Cookies.set(UserId, useId)
}

export function removeUserId() {
    return Cookies.remove(UserId)
}
export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getUsername() {
    return Cookies.get(Username)
}

export function setUsername(username) {
    return Cookies.set(Username, username)
}

export function removeUsername() {
    return Cookies.remove(Username)
}
export function getUserType() {
    return Cookies.get(UserType)
}
export function setUserType(userType) {
    return Cookies.set(UserType, userType)
}
export function removeUserType() {
    return Cookies.remove(UserType)
}
export function getOrgId() {
    return Cookies.get(OrgId)
}

export function setOrgId(orgId) {
    return Cookies.set(OrgId, orgId)
}
export function removeOrgId() {
    return Cookies.remove(OrgId)
}
export function getOrgCode() {
    return Cookies.get(OrgCode)
}

export function setOrgCode(orgCode) {
    return Cookies.set(OrgCode, orgCode)
}
export function getRoute() {
    return Cookies.get(Route)
}
export function setRoute(route) {
    return Cookies.set(Route, route)
}
export function removeRout() {
    return Cookies.remove(Route)
}

export function getPassword() {
    return Cookies.get(Password)
}
export function setPassword(password) {
    return Cookies.set(Password, password)
}
export function removePassword() {
    return Cookies.remove(Password)
}

export function getMassifId() {
    return Cookies.get('massifId')
}
export function setMassifId(password) {
    return Cookies.set('massifId', password)
}
export function removeMassifId() {
    return Cookies.remove('massifId')
}