<template>
    <div class="layout-main">
        <div class="tool-bottom">
            <!-- <van-row v-if="isLogin">
                <van-col span="8">
                    <router-link to="/home" class="tool-item">
                        <div class="tool-img">
                            <img src="../assets/images/home/u367.png">
                        </div>
                        <div class="tool-text">首页</div>
                    </router-link>
                </van-col>
                <van-col span="8">
                    <router-link to="/console" class="tool-item">
                        <div class="tool-img">
                            <img src="../assets/images/home/u367.png">
                        </div>
                        <div class="tool-text">控制台</div>
                    </router-link>
                </van-col>
                <van-col span="8">
                    <router-link to="/personal" class="tool-item">
                        <div class="tool-img">
                            <img src="../assets/images/home/u367.png">
                        </div>
                        <div class="tool-text">个人中心</div>
                    </router-link>
                </van-col>
            </van-row>
            <van-row v-else>
                <van-col span="8">
                    <router-link to="/personal" class="tool-item">
                        <div class="tool-img">
                            <img src="../assets/images/home/u367.png">
                        </div>
                        <div class="tool-text">电商购买</div>
                    </router-link>
                </van-col>
                <van-col span="8">
                    <router-link to="/personal" class="tool-item">
                        <div class="tool-img">
                            <img src="../assets/images/home/u367.png">
                        </div>
                        <div class="tool-text">溯源查看</div>
                    </router-link>
                </van-col>
                <van-col span="8">
                    <router-link to="/personal" class="tool-item">
                        <div class="tool-img">
                            <img src="../assets/images/home/u367.png">
                        </div>
                        <div class="tool-text">个人中心</div>
                    </router-link>
                </van-col>
            </van-row> -->
             <van-tabbar v-model="active" @change="changeItem">
                <van-tabbar-item icon="search" name="console" to="/console">
                    <span>控制台</span>
                    <template #icon="props">
                        <img :src="props.active ? icon.activeHome : icon.inactiveHome" />
                    </template>
                </van-tabbar-item>
                <van-tabbar-item icon="friends-o" name="personal" to="/personal">
                    <span>个人中心</span>
                    <template #icon="props">
                        <img :src="props.active ? icon.activePersonal : icon.inactivePersonal" />
                    </template>
                </van-tabbar-item>
             </van-tabbar>
            <!-- <van-tabbar v-model="active" v-if="isLogin" @change="changeItem">
                <van-tabbar-item icon="home-o" name="Home" to="/home">首页</van-tabbar-item>
                <van-tabbar-item icon="search" name="console" to="/console">控制台</van-tabbar-item>
                <van-tabbar-item icon="friends-o" name="personal" to="/personal">个人中心</van-tabbar-item>
            </van-tabbar>
            <van-tabbar v-model="active" v-else @change="changeItem">
                <van-tabbar-item icon="home-o" name="bug">电商购买</van-tabbar-item>
                <van-tabbar-item icon="search" name="suyuan">溯源查看</van-tabbar-item>
                <van-tabbar-item icon="friends-o" name="personal" to="/personal">个人中心</van-tabbar-item>
            </van-tabbar>
            <van-tabbar v-model="active" v-if="isTourist" @change="changeItem">
                <van-tabbar-item icon="home-o" name="Home" to="/home">首页</van-tabbar-item>
                <van-tabbar-item icon="search" name="suyuan">溯源查看</van-tabbar-item>
                <van-tabbar-item icon="friends-o" name="personal" to="/personal">个人中心</van-tabbar-item>
            </van-tabbar> -->
        </div>
    </div>
</template>

<script>
import {getToken, getUserType} from '@/utils/cookie'
import wx from 'weixin-js-sdk'
export default {
    data() {
        return {
            isLogin: true,//判断是否登录
            isTourist: false,//判断是否是游客
            active: 'home',
            icon: {
                activeHome: require('@/assets/images/layout/icon_tab_home_selected.svg'),
                inactiveHome: require('@/assets/images/layout/icon_tab_home_Unselected.svg'),
                activePersonal: require('@/assets/images/layout/icon_tab_my_selected.svg'),
                inactivePersonal: require('@/assets/images/layout/icon_tab_my_unselected.svg'),
            },
        }
    },
    mounted() {
        this.isLogin = getToken() ? true : false;
        this.isTourist = getUserType() == 3 ? true : false;
        
    },
    methods: {
        /** 切换 */
        changeItem(val) {
            if (val == 'buy') {

            } else if (val == 'suyuan') {
                this.$router.push({name: 'scanQRCode'});
                /* wx.miniProgram.reLaunch({
                    url: '/pages/scan/scan'
                })  */
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .layout-main {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 50px;
        z-index: 99999;
        background-color: #fff;
        .tool-item {
            text-align: center;
            .tool-img {
                width: 100%;
                line-height: 28px;
                margin-bottom: 4px;
            }
            img {
                width: 22px;
                height: 22px;
                vertical-align: middle;
            }
            .tool-text {
                color: #646566;
                font-size: 12px;
                line-height: 1;
                cursor: pointer;
            }
        }
        .van-tabbar-item--active {
            color: #3498F6;
        }
    }
</style>