import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import store from './store'
import 'vant/lib/index.css';
import router from './router';
import 'amfe-flexible/index.js';
import VueWechatTitle from 'vue-wechat-title';
import VueTouch from 'vue-touch';//监听移动端左右滑动
import echarts from 'echarts';
import RSA from '@/utils/rsa.js';
//全局样式
import './style/index.scss';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import '@/utils/index.css'
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import  './style/index.scss'

Vue.use(animated)
// use
Vue.use(mavonEditor)
Vue.use(Vant);
Vue.use(VueWechatTitle);
Vue.use(VueTouch, { name: 'v-touch' });
VueTouch.config.swipe = {
    threshold: 10 //手指左右滑动触发事件距离
}

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts ;
Vue.prototype.RSA = RSA;

new Vue({
    router,
    store,
  render: h => h(App),
}).$mount('#app')
