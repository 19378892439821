import {login} from '@/api/user'
import {getUserId,setUserId,removeUserId,getToken,setToken,removeToken,getUsername,setUsername,removeUsername,getOrgId,setOrgId,setOrgCode,getOrgCode,getPassword } from '@/utils/cookie'
import router from '@/router'

const state = {
    userId: getUserId(),
    token: getToken(),
    username: getUsername(),
    password:getPassword(),
    orgId: getOrgId(),
    orgCode: getOrgCode(),
    alartImei: '',
    alarmType: '',
    alarmTime: '',
}
const mutations = {
    SET_USERID:(state, userId) => {
        state.userId = userId
    },
    SET_TOKEN:(state, token) => {
        state.token = token
    },
    SET_USERNAME:(state, username) => {
        state.username = username
    },
    SET_ORGID:(state, orgId) => {
        state.orgId = orgId
    },
    SET_ORGCODE:(state, orgCode) => {
        state.orgCode = orgCode
    },
    SET_PASSWORD:(state, password) => {
        state.password = password
    },
    SET_ALARTIMEI:(state, imei) => {
        state.alartImei = imei
    },
    SET_ALARTTYPE:(state, alarmType) => {
        state.alarmType = alarmType
    },
    SET_ALARTTIME:(state, alarmTime) => {
        state.alarmTime = alarmTime
    },
}

const actions = {
    //用户登录
    login({ commit },userInfo){
        const {username,password} = userInfo;
        return new Promise((resolve,reject) => {
            login({ username: username.trim(),password:password }).then(response => {
                if(response.code == 200 ) {
                    const { data } = response
                    commit('SET_USEID',data.userId);
                    setUserId(data.userId);
                    commit('SET_TOKEN',data.token);
                    setToken(data.token);
                    commit('SET_USERNAME',data.userName);
                    setUsername(data.userName);
                    commit('SET_ORGID',data.orgId);
                    setOrgId(data.orgId);
                    commit('SET_ORGCODE',data.orgCode);
                    setOrgCode(data.orgCode);
                    // debugger
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    //用户退出登录
    logout({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', '')
            removeToken()
            router.push('/login')
            resolve()
        })
    },
    //用户退出登录
    setAlartImei({commit}, val) {
        return new Promise((resolve, reject) => {
            commit('SET_ALARTIMEI', val.imei)
            commit('SET_ALARTTYPE', val.alarmType)
            commit('SET_ALARTTIME', val.alarmTime)
            resolve()
        })
    },
}

export default {
    namespaced:true,
    state,
    mutations,
    actions
}
