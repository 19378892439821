import { getRoute, setRoute } from '@/utils/cookie'
import { removeRout } from '../../utils/cookie';

const state = {
    route: getRoute(),
}
const mutations = {
    SET_ROUTE:(state, route) => {
        state.route = route;
    },
}

const actions = {
    setPermissionRoutes({ commit },route){
        return new Promise((resolve, reject) => {
            commit('SET_ROUTE',route);
            setRoute(route);
            resolve();
        })
    },
    clearRoute({ commit },route) {
        return new Promise((resolve, reject) => {
            commit('SET_ROUTE','');
            removeRout();
            resolve();
        })
    },
}

export default {
    namespaced:true,
    state,
    mutations,
    actions
}