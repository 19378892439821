import axios from 'axios'
import { Toast } from 'vant';
import router from '@/router'
import { getToken , removeToken, getUserType} from '@/utils/cookie'
import $config from './config' //引入不同的环境地址

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

//创建axios 实例
const service = axios.create({
    //超时
    timeout:30000
});
//区分不同环境地址
switch(process.env.VUE_APP_CURENV){
    case 'test':
        service.defaults.baseURL = $config.apiUrl.test
        break;
    case 'development':
        service.defaults.baseURL = $config.apiUrl.development
        // service.defaults.baseURL = 'http://test-nj.jimicloud.com/api'
        break;
    case 'production':
        // service.defaults.baseURL = $config.apiUrl.production
        service.defaults.baseURL = 'https://test-nj.jimicloud.com/api'
        break;
}
console.log(service.defaults.baseURL, 'aa')
//请求拦截器
service.interceptors.request.use(
    res => {
        res.headers.token = getToken();
        return res;
    },
    error => {
        return Promise.reject(error);
    }
)

//响应拦截器
service.interceptors.response.use(
    res => {
        //未设置状态密码默认成功状态
        const code = res.data.code || 200;
        //获取错误信息
        if(code == 101){
            // Toast.fail('系统繁忙');
            return Promise.reject('error')
        }else if(code == 100){
            // Toast.fail('失败-'+res.data.message);
            // return Promise.reject('error')
            return res.data
        }else if(code == 200){
            return Promise.resolve(res.data)
        }else if(code == 301){
            // Toast.fail('流量限流异常');
            return Promise.reject('error')
        }else if(code == 311){
            // Toast.fail('限流(系统繁忙)');
            return Promise.reject('error')
        }else if(code == 321){
            // Toast.fail('熔断');
            return Promise.reject('error')
        }else if(code == 20003){
            // Toast.fail('无效的围栏名称');
            return Promise.reject('error')
        }else if(code == 4000){
            // Toast.fail('未知错误');
            return Promise.reject('error')
        }else if(code == 4001){
            // Toast.fail('非法请求');
            return Promise.reject('error')
        }else if(code == 4002){
            // Toast.fail('非法字符');
            return Promise.reject('error')
        }else if(code == 4003){
            // Toast.fail('参数错误');
            return Promise.reject('error')
        }else if(code == 4004){
            // Toast.fail('状态错误');
            return Promise.reject('error')
        }else if(code == 4005){
            // Toast.fail('没有数据');
            return Promise.reject('error')
        }else if(code == 4006){
            // Toast.fail('数据一致性错误');
            return Promise.reject('error')
        }else if(code == 4007){
            // Toast.fail('不合法的URL');
            return Promise.reject('error')
        }else if(code == 5000){
            // Toast.fail('用户不存在');
            return Promise.reject('error')
        }else if(code == 5001){
            // if (getUserType() && getUserType() != 3) {
                // Toast.fail('未登录');
                return Promise.reject('error')
            // }
        }else if(code == 5002){
            // if (getUserType() && getUserType() != 3) {
                // Toast.fail('登录失效，请重新登录');
                removeToken();
                router.push('/login')
                return Promise.reject('error')
            // }
            
        }else if(code == 5003){
            // Toast.fail('token异常');
            return Promise.reject('error')
        }else if(code == 5004){
            // Toast.fail('权限不足');
            return Promise.reject('error')
        }else if(code == 5005){
            // Toast.fail('名称重复');
            return Promise.reject('error')
        }else{
            return res.data
        }
    },
    error => {
        return Promise.reject(error);
    }
)
export default service; 